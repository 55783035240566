import React, { useState } from "react"; // Fragment
import { useNavigate } from "react-router-dom";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./subscribe.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "top_golf_0001.jpg",
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Subscribe", url: "/subscribe", active: true },
];

const Subscribe = () => {
  const navigate = useNavigate();
  const [registrationOption, setRegistrationOption] = useState(0);
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page">
        <div style={{ textAlign: "justify", textAlignLast: "center" }}>
          {MenSubscription()}
        </div>

        {/* Show marketing images */}
        <div style={{ padding: "10px" }}>
          {images.map((src, index) => (
            <div
              key={index}
              style={{ marginBottom: "20px" }} // 20px space between images
            >
              <img
                src={`https://s3.amazonaws.com/social-images-public/${src}`}
                alt={`image-${index}`}
                style={{
                  maxWidth: "100%", // make image responsive
                  height: "auto", // auto adjust the height
                }}
              />
            </div>
          ))}
        </div>

        <hr />

        <br />
      </div>
    </>
  );
};

export default Subscribe;

const WomenSubscription = () => {
  return (
    <div>
      {/* womens verification process */}
      <div style={{ textAlign: "justify", textAlignLast: "center" }}>
        <h4
          style={{
            marginTop: "-10px",
            marginBottom: "-10px",
            fontWeight: "bold",
          }}
        >
          Women's Verification Options
        </h4>
        <hr />
        <p style={{ lineHeight: 1.1 }}>
          Option 1: Get recommendation from any woman or admin in the group
        </p>
        <p style={{ lineHeight: 1.1 }}>
          Option 2:{" "}
          <a
            href={
              "https://instagram.com/" + process.env.REACT_APP_INSTAGRAM_HANDLE
            }
            target="_blank"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Follow us
          </a>{" "}
          on Instagram, and approve us to follow you so we can see that there
          are photos of you from a while back and it seems legit to us.
        </p>
        <p style={{ lineHeight: 1.1 }}>
          Option 3: Meet us at a public event so we can see that you are who you
          say you are.
        </p>
        <p style={{ lineHeight: 1.1 }}>
          Option 4: Follow the{" "}
          <a
            href="/membership-men"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            mens registration
          </a>{" "}
          process, and pay the monthly fee. Once we meet you at a public or
          private event, you will no longer be required to pay the monthly fee.
        </p>
      </div>
    </div>
  );
};

const NoSubscriptionSelectedDiv = () => {
  return (
    <div>
      <p style={{ lineHeight: 1.1 }}>
        Event locations are shown to members only. <br />{" "}
        <span
          style={{
            fontStyle: "italic",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Women join free.
        </span>{" "}
        Men pay ${process.env.REACT_APP_MEMBERSHIPCOST_USD} / mo.
      </p>
      <p style={{ lineHeight: 1.1 }}>
        We see this fee as a challenge - an opportunity to step up, support our
        community, and show respect for our female members. Are you ready to
        join our ranks? See you at the next event 🥳
      </p>
    </div>
  );
};

const MenSubscription = () => {
  return (
    <div>
      <div
        style={{
          textAlign: "justify",
          textAlignLast: "center",
          padding: "20px",
        }}
      >
        <h4
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            marginBottom: "0.5rem",
          }}
        >
          Mens Membership
        </h4>
        <hr style={{ marginBottom: "1rem" }} />
        <p style={{ lineHeight: 1.5 }}>
          <a href="/unsubscribe">UNSUBSCRIBE HERE</a>.
        </p>
        <p style={{ lineHeight: 1.5 }}>Subscribe below.</p>

        <hr />
        <p style={{ lineHeight: 1.5 }}>
          The chat is where we post event locations. It's just for
          announcements.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          Since we began, we've tried to adopt a chivalry subscription model.
          Women join for free, and men are required to pay a monthly membership
          fee. This helps to pay for the costs of the group and to maintain a
          good balance between the sexes so it's not too one-sided like a lot of
          groups organized through the internet.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          To view event locations in the GroupMe announcements-only chat, you
          must be a member. Men must pay a monthly $
          {process.env.REACT_APP_MEMBERSHIPCOST_USD} subscription fee{" "}
          <a
            href="https://buy.stripe.com/8wMdUS74j9nA5sA289"
            target="_blank"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            HERE
          </a>
          . Everyone will be able to see titles of upcoming events, but only
          members will be able to see the locations of certain events.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          We know this seems like a big ask at first, but this is a small
          portion of the monthly costs to run the group to purchase supplies for
          events, extra pickleball paddles, volleyballs, Meetup organizer
          subscription fees, etc. So we're asking the men to share that with us
          whether they are playing or just watching and hanging out. We're
          asking all the guys to step up with us to provide nice events for the
          ladies so the ladies can simply show up and enjoy the events, and
          everything will be taken care of.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          Again, we know it's a lot to ask, but if you would be willing to join
          in as a supporter, it would really mean a lot and would help out the
          group.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          Since this is basically the cost of a drink or two at a bar, our
          commitment to you is that we will always provide a better time than a
          bar or restaurant. And that these events will keep you active while
          having fun and making new friends.
        </p>
        <p style={{ lineHeight: 1.5, display: "none" }}>
          If men pay in Bitcoin Cash, they only pay $
          {process.env.REACT_APP_MEMBERSHIPCOST_BCH} per month, but they must
          pay in 6-month increments - $
          {process.env.REACT_APP_MEMBERSHIPCOST_BCH * 6} total. You can pay with
          Bitcoin Cash{" "}
          <a
            href="/paybch"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            HERE
          </a>
          . You may have to contact me after you've paid so I can verify the
          payment status and add you as a member to our group, so please contact
          the group account on Instagram by{" "}
          <a
            href={
              "https://instagram.com/" + process.env.REACT_APP_INSTAGRAM_HANDLE
            }
            target="_blank"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            following us
          </a>{" "}
          and sending a direct message.
        </p>
        <p style={{ lineHeight: 1.5, display: "none" }}>
          We see this fee as a challenge - an opportunity to step up, support
          our community, and show respect for our female members. Are you ready
          to join our ranks? See you at the next event 🥳
        </p>
      </div>

      {/* stripe buy button */}
      <StripeBuyButton />
    </div>
  );
};
